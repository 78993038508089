





















































// Components
import { Component, Vue } from 'vue-property-decorator';

// GraphQL
import dashboardAppsyncClient from '@/lib/appsync/dashboard';
import getDashboardData from '@/graphql/getDashboardData.graphql';

// Dates
import { addMinutes, startOfDay } from 'date-fns';

import { namespace } from 'vuex-class';
import MainTimeOption from '@/interfaces/mainTimeOption';  // eslint-disable-line
import { formatDate } from '@/utils/dateHelpers'; // eslint-disable-line

const appModule = namespace('appModule');

@Component
export default class CentralLoggingTable extends Vue {
  @appModule.Getter
  public currentTimeOption!: MainTimeOption

  public centralLoggingItems: {
    id: number,
    repo: string,
    errorsCount: number|string,
    warningsCount: number|string,
  }[] = [];

  public loading: boolean = false;

  public async fetchData(): Promise<void> {
    this.loading = true;
    this.centralLoggingItems = [];

    /*
      Fetch central logging
     */
    const {
      data:
        {
          get_dashboard_data: {
            results: centralLoggingResults,
          },
        },
    } = await dashboardAppsyncClient.query({
      query: getDashboardData,
      variables: {
        input: {
          start_date:
            formatDate(startOfDay(addMinutes(
              new Date(), new Date().getTimezoneOffset() - this.currentTimeOption.value * 1440,
            )), "yyyy-MM-dd'T'HH:mm:ss"),
          end_date: formatDate(startOfDay(addMinutes(new Date(), new Date().getTimezoneOffset())),
            "yyyy-MM-dd'T'HH:mm:ss"),
          env: 'prod',
          record_type: 'central_logging',
        },
      },
    });
    const parsedCentralData = centralLoggingResults.map((i) => JSON.parse(i));
    const labels = [...new Set(parsedCentralData.map((i) => i.stackName))];
    labels.forEach((label, idx) => this.centralLoggingItems.push({
      id: idx,
      repo: String(label),
      errorsCount: parsedCentralData.filter((i) => i.levelname === 'ERROR' && i.stackName === label).length,
      warningsCount: parsedCentralData.filter((i) => i.levelname === 'WARNING' && i.stackName === label).length,
    }));
    this.loading = false;
  }

  async created() {
    await this.fetchData();
    this.$eventBus.$on('timeAppChanged', () => {
      this.fetchData();
    });
  }
}
